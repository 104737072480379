<template>
  <div class="content">
    <a-affix :offset-top="0">
      <div class="search-top">
        <div class="back" @click="$router.back()">
          <icon-font type="icon-fanhui" />
        </div>

        <div class="search-input">
          <input type="text" v-model="dishName" />
          <div class="search-btn" @click="onSearchDish">
            搜索
          </div>
        </div>
      </div>
    </a-affix>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
        <a-row :gutter="[10, 10]">
          <a-col v-for="(v, k) in list" :key="k" :span="12">
            <a-card  @click="$router.push({name: 'handlers-dish', params: {id: v.id}})">
              <template #cover>
                <div class="image">
                  <img :alt="v.name" :src="$filters.image(v.pic)" />
                </div>
              </template>
              <a-card-meta
                :title="v.name"
                :description="'售价：¥' + v.sellingPrice"
              />
            </a-card>
          </a-col>
        </a-row>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { List, PullRefresh } from 'vant'
import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import { DishClass } from '@/apis/dish'

const dish = new DishClass()
export default defineComponent({
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  setup () {
    const queryParams = ref({
      Limit: 20
    })
    const paginate = ref({
      total: 0,
      page: 1
    })
    const list = ref([])
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)

    const dishName = ref('')

    const onLoad = () => {
      console.log('加载', paginate.value.total, paginate.value.page)
      if (
        paginate.value.page * queryParams.value.Limit >= paginate.value.total ||
        paginate.value.total <= queryParams.value.Limit
      ) {
        finished.value = true
      } else {
        getDataList(++paginate.value.page)
      }
    }

    const getDataList = (page) => {
      loading.value = true
      if (page === 1) {
        list.value = []
      }

      paginate.value.page = page
      const q = { ...queryParams.value }

      q.Offset = (page - 1) * q.Limit
      q.Type = 0
      dish.gets(q).then((resp) => {
        list.value.push(...resp.data)

        paginate.value.total = resp.total

        loading.value = false
        refreshing.value = false

        if (page >= resp.total) {
          finished.value = true
        } else {
          finished.value = false
        }
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      getDataList(1)
    }

    const onSearchDish = () => {
      queryParams.value.Name = dishName.value
      getDataList(1)
    }

    return {
      queryParams,
      paginate,
      list,
      loading,
      finished,
      refreshing,
      dishName,
      onLoad,
      onRefresh,
      getDataList,
      onSearchDish
    }
  },

  created () {
    this.getDataList(1)
  },
  methods: {}
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #eeeeee;
  .search-top {
    position: relative;
    padding-left: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #fff;
    .back {
      position: absolute;
      top: 10px;
      left: 0;
      width: 40px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 800;
    }
    .search-input {
      position: relative;
      padding-right: 70px;
      input {
        display: block;
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 16px;
        padding: 3px 10px;
      }
      .search-btn {
        position: absolute;
        top: 0;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        height: 32px;
        width: 50px;
        color: #fff;
        background: #f50;
        box-shadow: 0 0 3px rgba(100, 0, 0, 0.3);
      }
    }
  }

  .list{
    padding: 0.3rem 0.2rem;
    .image{
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
